/* eslint-disable no-unused-vars */
import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { isValidToken, setSession } from 'src/utils/jwt';
import { ajaxUrl } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  uploadingProgress: 0,
  uploadingMessage: ''
};

const slice = createSlice({
  name: 'film',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setUploadingProgress(state, action) {
			state.uploadingProgress = action.payload;
    },

    setUploadingMessage(state, action) {
      state.uploadingMessage = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setUploadingProgress,
  setUploadingMessage
} = slice.actions;

// ----------------------------------------------------------------------

