import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, Link, Container, Typography, Button, Dialog, Select, MenuItem, Divider } from '@material-ui/core';
import { motion } from 'framer-motion';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

import { varFadeInRight } from 'src/components/animate';
import { useEffect, useState } from 'react';
import { getBannerPhoto } from 'src/redux/slices/film';
import { useDispatch, useSelector } from 'src/redux/store';
import { LoadingButton } from '@material-ui/lab';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from 'src/firebase/config';
import GoogleIcon from '@material-ui/icons/Google';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundPosition: 'contain',
  },
  backgroundColor: 'black',
  backgroundImage: 'url("/static/home/hero.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}));

export const RegisterImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  width: '100%',
  height: '100%',
  margin: 'auto',
  objectFit: 'cover',
  position: 'absolute',
  zIndex: 0,
  filter: `drop-shadow(40px 80px 80px rgba(0, 0, 0, 0.28))`,
  [theme.breakpoints.up('lg')]: {
    // right: '8%',
    width: '100%',
    height: '100vh'
  }
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  zIndex: 3,
}));

// ----------------------------------------------------------------------
const provider = new GoogleAuthProvider();
export const handleGoogleLogin = async (setGoogleLoading, googleLogin, role) => {
    try {
        setGoogleLoading(true);
        const { user } = await signInWithPopup(auth, provider);
        const payload = {
            firstName: user.displayName.split(' ')[0],
            lastName: user.displayName.split(' ')[1],
            role: role,
            uid: user.uid,
            accessToken: user.stsTokenManager.accessToken,
            email: user.email,
            photoURL: user.photoURL,
            expiration: user.stsTokenManager.expirationTime
        };
        await googleLogin(payload);
        setGoogleLoading(false);
    } catch (error) {
        setGoogleLoading(false);
    }
};

function SimpleDialog(props) {
    const { onClose, selectedValue, open, role, setRole, onYes } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            PaperProps={{ sx: { minWidth: { xs: '90vw', md: '40vw' } } }}
        >
            <Box sx={{ padding: 3 }}>
                <Select
                    sx={{ color: '#949494', my: 2, width: '100%' }}
                    fullWidth
                    labelId="label-role"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    inputProps={{
                        style: { color: '#D1CFCE' }
                    }}
                >
                    <MenuItem value={'screener'} selected>
                        Screener
                    </MenuItem>
                    <MenuItem value={'creator'}>Creator</MenuItem>
                </Select>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
                    <Button variant="outlined" onClick={onClose}>
                        No
                    </Button>
                    <Button variant="outlined" onClick={onYes}>
                        Yes
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
export default function Login() {
    const { method, login, googleLogin } = useAuth();
    // const dispatch = useDispatch();
    // const { landingPhoto } = useSelector((state) => state.film);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [guestLoading, setGuestLoading] = useState(false);
    const [role, setRole] = useState('screener');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     dispatch(getBannerPhoto());
    // }, []);

    const handleLoginAuth0 = async () => {
        try {
            await login();
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickGuestMode = async () => {
        setGuestLoading(true);
        await login('guestscreenindie@gmail.com', 'abcabcabc');
        setGuestLoading(false);
    };

  return (
    <RootStyle title="Login | Screen Indie">
      <SimpleDialog open={open} onClose={handleClose} role={role} setRole={setRole} onYes={() => {
        handleClose()
        handleGoogleLogin(setGoogleLoading, googleLogin, role)
      }
      } />
      {/* <RegisterImgStyle alt="hero" // src={landingPhoto?.url || "/static/home/hero.png"}
        variants={varFadeInRight}
        className={'lazyload blur-up'}
        // data-src={landingPhoto?.url || "/static/home/hero.png"}
        // data-srcset={`${landingPhoto?.url || "/static/home/hero.png"} 300w`}
        data-src={"/static/home/hero.png"}
        data-srcset={"/static/home/hero.png"}
      /> */}
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Get started
        </Link>
      </AuthLayout>

            {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/brand/film1.jpeg" alt="login" />
        </SectionStyle>
      </MHidden> */}

            <Container>
                <ContentStyle>
                    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography sx={{ color: '#ffffff' }} variant="h3" gutterBottom>
                                Sign in to Screen Indie
                            </Typography>
                            <Typography sx={{ color: '#d4d4d4' }}>Enter your details below.</Typography>
                        </Box>
                    </Stack>

                    {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>alex@alex.com</strong> / password :<strong>&nbsp;test1234</strong>
          </Alert> */}

                    {method !== 'auth0' ? (
                        <LoginForm />
                    ) : (
                        <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                            Login
                        </Button>
                    )}

                    <MHidden width="smUp">
                        <Typography variant="body" align="center" sx={{ my: 3 }}>
                            Don’t have an account?&nbsp;
                            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                                Get started
                            </Link>
                        </Typography>
                    </MHidden>

                    <Divider sx={{ my: 2 }} />

                    <LoadingButton
                        type={'button'}
                        size="large"
                        loading={googleLoading}
                        variant="outlined"
                        color="info"
                        onClick={handleClickOpen}
                        startIcon={<GoogleIcon />}
                        sx={{}}
                    >
                        Google
                    </LoadingButton>
                    <LoadingButton
                        type={'button'}
                        size="large"
                        loading={guestLoading}
                        variant="outlined"
                        onClick={handleClickGuestMode}
                        startIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M17.06 13c-1.86 0-3.42 1.33-3.82 3.1c-.95-.41-1.82-.3-2.48-.01C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17s1.77 4 3.94 4c2.06 0 3.74-1.62 3.9-3.68c.34-.24 1.23-.69 2.32.02c.18 2.05 1.84 3.66 3.9 3.66c2.17 0 3.94-1.79 3.94-4s-1.77-4-3.94-4M6.94 19.86c-1.56 0-2.81-1.28-2.81-2.86s1.26-2.86 2.81-2.86c1.56 0 2.81 1.28 2.81 2.86s-1.25 2.86-2.81 2.86m10.12 0c-1.56 0-2.81-1.28-2.81-2.86s1.25-2.86 2.81-2.86s2.82 1.28 2.82 2.86s-1.27 2.86-2.82 2.86M22 10.5H2V12h20zm-6.47-7.87c-.22-.49-.78-.75-1.31-.58L12 2.79l-2.23-.74l-.05-.01c-.53-.15-1.09.13-1.29.64L6 9h12l-2.44-6.32z"
                                ></path>
                            </svg>
                        }
                        sx={{ mt: 2 }}
                    >
                        Guest mode
                    </LoadingButton>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
