import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
// import { isValidToken, setSession } from 'src/utils/jwt';
import { ajaxUrl } from 'src/config';
import { setUploadingProgress, setUploadingMessage } from './uploadingProgress';
import { uploadFile, uploadVideo } from 'src/utils/uploadFile';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    films: [],
    featureFilm: null,
    featureFilmTrailer: null,
    film: null,
    ownFilms: [],
    filmsToLanding: null,
    filmsToLandingSearch: [],
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: ''
    },
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null
    },
    landingPhoto: ''
};

const slice = createSlice({
    name: 'film',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PRODUCTS
        getFilmsSuccess(state, action) {
            state.isLoading = false;
            state.films = action.payload;
        },
        getOwnFilmsSuccess(state, action) {
            state.isLoading = false;
            state.ownFilms = action.payload;
        },
        getFeatureFilmsSuccess(state, action) {
            state.isLoading = false;
            state.featureFilm = { ...action.payload?.[0]?.filmId, ...action.payload?.[0] };
        },
        getFeatureFilmToLandingSuccess(state, action) {
            state.isLoading = false;
            state.featureFilmTrailer = action.payload;
        },
        getFilmsToLandingSuccess(state, action) {
            state.isLoading = false;
            state.filmsToLanding = action.payload;
        },

        setFilmsToLandingSearch(state, action) {
            const allFilms = Object.values(action.payload).flatMap((genre) => genre.films);
            const uniqueFilms = uniqBy(allFilms, '_id');
            state.filmsToLandingSearch = uniqueFilms;
        },

        // GET PRODUCT
        getFilmSuccess(state, action) {
            state.isLoading = false;
            state.film = action.payload;
        },

        // ADD FILM
        addFilmSuccess(state, action) {
            state.isLoading = false;
            state.film = action.payload;
        },
        addFeatureFilmSuccess(state, action) {
            state.isLoading = false;
            state.featureFilm = { ...action.payload?.[0]?.filmId, ...action.payload?.[0] };
        },

        // DELETE PRODUCT
        deleteFilm(state, action) {
            state.films = reject(state.films, ['_id', action.payload]);
        },

        banFilm(state, action) {
            const deletedFilm = state.films?.filter((film) => film._id !== action.payload);
            state.films = deletedFilm;
            state.isLoading = false;
        },

        //  SORT & FILTER PRODUCTS
        sortByFilms(state, action) {
            state.sortBy = action.payload;
        },

        filterFilms(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },

        getBannerPhotoSuccess(state, action) {
            state.isLoading = false;
            state.landingPhoto = action.payload;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((film) => film.price * film.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const film = action.payload;
            const isEmptyCart = state.checkout.cart.length === 0;

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, film];
            } else {
                state.checkout.cart = map(state.checkout.cart, (_film) => {
                    const isExisted = _film.id === film.id;
                    if (isExisted) {
                        return {
                            ..._film,
                            quantity: _film.quantity + 1
                        };
                    }
                    return _film;
                });
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, film], 'id');
        },

        deleteCart(state, action) {
            const updateCart = filter(state.checkout.cart, (item) => item.id !== action.payload);

            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },

        increaseQuantity(state, action) {
            const filmId = action.payload;
            const updateCart = map(state.checkout.cart, (film) => {
                if (film.id === filmId) {
                    return {
                        ...film,
                        quantity: film.quantity + 1
                    };
                }
                return film;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const filmId = action.payload;
            const updateCart = map(state.checkout.cart, (film) => {
                if (film.id === filmId) {
                    return {
                        ...film,
                        quantity: film.quantity - 1
                    };
                }
                return film;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    deleteFilm,
    createBilling,
    applyShipping,
    applyDiscount,
    filterFilms,
    sortByFilms,
    increaseQuantity,
    decreaseQuantity
} = slice.actions;

// ----------------------------------------------------------------------

export function getFilms() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${ajaxUrl}/film`);
            dispatch(
                slice.actions.getFilmsSuccess(
                    [...response.data]?.sort((a, b) => {
                        return new Date(b?.createdAt || b?.date) - new Date(a?.createdAt || a?.date);
                    })
                )
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFeatureFilms() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${ajaxUrl}/featureFilm`);
            const sorted = response.data?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
            dispatch(slice.actions.getFeatureFilmsSuccess(sorted));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFeatureFilmToLanding() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data } = await axios.get(`${ajaxUrl}/featureFilm/landing-trailler`);
            dispatch(slice.actions.getFeatureFilmToLandingSuccess(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFilmsToLanding() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data } = await axios.get(`${ajaxUrl}/film/landing`);
            dispatch(slice.actions.getFilmsToLandingSuccess(data));
            dispatch(slice.actions.setFilmsToLandingSearch(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ---------------------------------------------------------------------

export function getFilm(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${ajaxUrl}/film/${id}`);
            dispatch(slice.actions.getFilmSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addFilm(
    values,
    userId,
    setSubmitting,
    enqueueSnackbar,
    createLog,
    setOtherFormat,
    handleComplete = () => null
) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        setSubmitting(true);
        try {
            const { photos, teaser, thumbnail, videos, ...filmData } = values;
            const formData = new FormData();

            const progressCallback = (progress) => {
                dispatch(setUploadingProgress(Math.floor((progress / 100) * 100)));
            }

            dispatch(setUploadingMessage('Uploading...'))

            if (photos && photos.length) {
                formData.append('photos', photos[0]);
            }

            if (thumbnail && thumbnail.length) {
                formData.append('thumbnail', thumbnail[0]);
            }

            if (teaser && teaser.length) {
                dispatch(setUploadingMessage('Uploading teaser...'))
                const { uploadedFileUrl: teaserUrl } = await uploadVideo(teaser[0], 'smooth', progressCallback);
                formData.append('teaser', teaserUrl);
            }

            if (videos && videos.length) {
                dispatch(setUploadingMessage('Uploading film...'))
                const { uploadedFileUrl: videoUrl } = await uploadVideo(videos[0], 'smooth', progressCallback);
                formData.append('streamingUrls', [videoUrl]);
            }

            Object.keys(filmData).forEach((key) => {
                if (key === 'splitPay' || key === 'cast') {
                    return filmData[key].map((obj) => formData.append(`${key}[]`, JSON.stringify(obj)));
                } else {
                    formData.append(key, filmData[key]);
                }
            });

            dispatch(setUploadingMessage('Uploading film data...'))

            const response = await axios.post(`${ajaxUrl}/film/addFilm/${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    dispatch(setUploadingProgress(percentCompleted));
                }
            });

            dispatch(slice.actions.addFilmSuccess(response.data));
            dispatch(setUploadingProgress(100));
            dispatch(setUploadingMessage(''))
            let film = response.data.film;
            setSubmitting(false);
            enqueueSnackbar(`Create success, Film Uploaded Successfully.`, { variant: 'success' });
            createLog();
            dispatch(setUploadingProgress(0));
            setOtherFormat(false);
            handleComplete(film?.thumbnail[0] || film?.photos[0]);
        } catch (error) {
            dispatch(setUploadingProgress(0));
            setSubmitting(false);
            console.log(error, 'film add error');
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function editFilm(
    values,
    keyObject,
    curFilm,
    setCurFilm,
    setSubmitting,
    enqueueSnackbar,
    createLog,
    setOtherFormat
) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        const { videos, teaser, ...filmData } = values;
        const formData = new FormData();

        const progressCallback = (percentCompleted) => {
            dispatch(setUploadingProgress(Math.floor((percentCompleted / 100) * 100)));
        }

        dispatch(setUploadingProgress(1))
        dispatch(setUploadingMessage('Uploading...'))

        try {
            setSubmitting(true);
            if (videos && videos.length && videos[0].name) {
                dispatch(setUploadingMessage('Uploading film...'))
                const { uploadedFileUrl: videoUrl } = await uploadVideo(videos[0], 'smooth', progressCallback);
                formData.append('streamingUrls', [videoUrl]);
            } else {
                formData.append('streamingUrls', curFilm.streamingUrls);
            }

            if (teaser && teaser.length && teaser[0].name) {
                dispatch(setUploadingMessage('Uploading teaser...'))
                const { uploadedFileUrl: teaserUrl } = await uploadVideo(teaser[0], 'smooth', progressCallback);
                formData.append('teaser', teaserUrl);
            } else {
                curFilm.teaser && formData.append('teaser', curFilm.teaser);
            }

            Object.keys(filmData).forEach((key) => {
                if (key === 'photos' || key === 'thumbnail') {
                    formData.append(`${key}Arr`, curFilm[key]);
                    return filmData[key].map((imgObj) => formData.append(key, imgObj));
                } else if (key === 'splitPay' || key === 'cast') {
                    return filmData[key].map((obj) => formData.append(`${key}[]`, JSON.stringify(obj)));
                } else {
                    return formData.append(key, filmData[key]);
                }
            });

            dispatch(setUploadingMessage('Uploading film data...'))

            const { data: { film } } = await axios.post(
                `${ajaxUrl}/film/editFilm`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(setUploadingProgress(percentCompleted));
                    }
                },
            );

            await dispatch(slice.actions.addFilmSuccess());
            let temp = JSON.parse(JSON.stringify(film || {}));
            temp.streamingUrls = [];
            setCurFilm(temp);
            setSubmitting(false);
            enqueueSnackbar(`Update success, Film Uploaded Successfully.`, { variant: 'success' });
            createLog();
            dispatch(setUploadingProgress(0));
            setOtherFormat(false);
        }
        catch (error) {
            dispatch(setUploadingProgress(0));
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateFilmBan(values) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await axios.post(`${ajaxUrl}/film/update/banned`, values);
            await dispatch(getFilms());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateFeatureFilm(values) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await axios.post(`${ajaxUrl}/featureFilm`, values);
            dispatch(slice.actions.addFeatureFilmSuccess(response.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getOwnCreatedFilms(userId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${ajaxUrl}/film/own_created?userId=${userId}`);
            dispatch(slice.actions.getOwnFilmsSuccess(response.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const updateBannerPhoto = (values) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const { imageFile } = values;
        try {
            const { uploadedFileUrl } = await uploadFile(imageFile, 'photo');
            const response = await axios.post(`${ajaxUrl}/photo/landing-page-banner`, { imageUrl: uploadedFileUrl });
            dispatch(slice.actions.getBannerPhotoSuccess(uploadedFileUrl));
            return response.data;
        } catch (error) {
            console.log(error, 'error');
            dispatch(slice.actions.hasError(error));
        }
    };
};

export const getBannerPhoto = () => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${ajaxUrl}/photo/get-banner`);
            dispatch(
                slice.actions.getBannerPhotoSuccess(
                    !response.data?.data?.length
                        ? ''
                        : response.data?.data?.sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))[0]
                )
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function deleteAFilm(id, enqueueSnackbar, cb) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${ajaxUrl}/film/${id}`);
            dispatch(slice.actions.banFilm(id));
            cb();
            enqueueSnackbar('Banned success', { variant: 'success' });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            enqueueSnackbar('Banned failure', { variant: 'error' });
        }
    };
}

export const handleWatchTime = (id, watchtime, userId) => {
    return async (dispatch) => {
        try {
            await axios.post(`${ajaxUrl}/film/watchtime/${id}`, { watchtime, userId });
        } catch (err) {
            console.log(err);
        }
    };
};
