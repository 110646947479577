// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
    blog: getIcon('ic_design'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    calendar: getIcon('ic_calendar'),
    talent: getIcon('ic_talent'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    spotlight: getIcon('spotlight-1'),
    activity: getIcon('feed')
};

const sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'general',
        items: [
            {
                title: 'home',
                path: PATH_DASHBOARD.general.home,
                icon: ICONS.dashboard
            },
            {
                title: 'activity feed',
                path: PATH_DASHBOARD.general.activity,
                icon: ICONS.activity
            },

            { title: 'my profile', path: PATH_DASHBOARD.user.profile, icon: ICONS.user },
            { title: 'stats', path: PATH_DASHBOARD.general.stats, icon: ICONS.user }
            // { title: 'browse', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
        ]
    },
    {
        subheader: 'Screen',
        items: [
            {
                title: 'films',
                path: PATH_DASHBOARD.screen.root,
                icon: ICONS.blog,
                children: [
                    { title: 'browse', path: PATH_DASHBOARD.screen.films, icon: ICONS.Creator },
                    { title: 'genre', path: PATH_DASHBOARD.screen.categories, icon: ICONS.blog },
                    { title: 'favorites', path: PATH_DASHBOARD.screen.favourite, icon: ICONS.analytics }
                ]
            },
            {
                title: 'my films',
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                children: [
                    // { title: 'creator', path: `${PATH_DASHBOARD.user.profile}/${JSON.parse(localStorage.getItem("user"))?._id}`, icon: ICONS.analytics },
                    {
                        title: 'create film',
                        path: PATH_DASHBOARD.user.newUser,
                        disabled: false
                    },
                    { title: 'edit film', path: PATH_DASHBOARD.user.editById, disabled: false }
                ]
            }
        ]
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'community',
        items: [
            // MANAGEMENT : USER
            {
                title: 'creators',
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                children: [
                    // { title: 'profile', path: `${PATH_DASHBOARD.user.profile}/${JSON.parse(localStorage.getItem("user"))?._id}` },
                    { title: 'creators', path: PATH_DASHBOARD.user.cards }
                    // { title: 'list', path: PATH_DASHBOARD.user.list },
                    // { title: 'create film', path: PATH_DASHBOARD.user.newUser },
                    // { title: 'edit film', path: PATH_DASHBOARD.user.editById },
                    // { title: 'account', path: PATH_DASHBOARD.user.account }
                ]
            },
            {
                title: 'Spotlight',
                path: PATH_PAGE.spotlight,
                icon: ICONS.spotlight
            },
            {
                title: 'Hire Production Crew',
                path: PATH_DASHBOARD.general.talent,
                icon: ICONS.talent
            }
            // MANAGEMENT : E-COMMERCE
            // {
            //   title: 'e-commerce',
            //   path: PATH_DASHBOARD.Creator.root,
            //   icon: ICONS.cart,
            //   children: [
            //     { title: 'shop', path: PATH_DASHBOARD.Creator.shop },
            //     { title: 'product', path: PATH_DASHBOARD.Creator.productById },
            //     { title: 'list', path: PATH_DASHBOARD.Creator.list },
            //     { title: 'create', path: PATH_DASHBOARD.Creator.newProduct },
            //     { title: 'edit', path: PATH_DASHBOARD.Creator.editById },
            //     { title: 'checkout', path: PATH_DASHBOARD.Creator.checkout },
            //     { title: 'invoice', path: PATH_DASHBOARD.Creator.invoice }
            //   ]
            // },

            // MANAGEMENT : BLOG
            // {
            //   title: 'films',
            //   path: PATH_DASHBOARD.film.root,
            //   icon: ICONS.blog,
            //   children: [
            //     { title: 'films', path: PATH_DASHBOARD.film.films },
            //     { title: 'film', path: PATH_DASHBOARD.film.filmById },
            //     { title: 'cast', path: PATH_DASHBOARD.film.newFilm }
            //   ]
            // }
        ]
    },

    // APP
    // ----------------------------------------------------------------------
    {
        subheader: 'app',
        items: [
            { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
            { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat }
        ]
    }
];

export default sidebarConfig;
