import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ajaxUrl } from 'src/config';
import { convertToDashed } from 'src/utils/helperFormik';
import { setUploadingProgress } from './uploadingProgress';
import { uploadFile } from 'src/utils/uploadFile';

const initialState = {
    isLoading: false,
    error: false,
    spotlights: [],
    spotlight: null
};

const slice = createSlice({
    name: 'film',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET PRODUCT
        getSpotlightsSuccess(state, action) {
            state.isLoading = false;
            state.spotlights = action.payload;
        },
        getSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        // ADD FILM
        addSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        editSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        deleteSpotlightSuccess(state, action) {
            state.isLoading = false;
            const filtered = [...state.spotlights].filter((v) => action.payload._id !== v._id);
            state.spotlights = filtered;
        }
    }
});

export default slice.reducer;

export const getSpotlights = (find) => {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.post(`${ajaxUrl}/spotlight`);
            dispatch(slice.actions.getSpotlightsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export const addSpotlight = (values, user, setSubmitting, enqueueSnackbar, reset) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { mainImage, subImage } = values;
            let payload = {
                ...values,
                userId: user._id,
                vanity: convertToDashed(values.vanity)
            }

            if (mainImage && mainImage.length) {
                const { uploadedFileUrl: mainImageUrl } = await uploadFile(mainImage[0], 'spotlight');
                payload.mainImage = [mainImageUrl];
            }

            if (subImage && subImage.length) {
                const { uploadedFileUrl: subImageUrl } = await uploadFile(subImage[0], 'spotlight');
                payload.subImage = [subImageUrl];
            }

            const response = await axios.post(`${ajaxUrl}/spotlight/create`, payload);
            console.log({ payload, abc: convertToDashed(values.title) });
            console.log({ data: response.data });
            dispatch(slice.actions.addSpotlightSuccess(response.data?.data));
            setSubmitting(false);
            enqueueSnackbar(`Create success, Spotlight Uploaded Successfully.`, { variant: 'success' });
            dispatch(setUploadingProgress(0));
            reset();
        } catch (error) {
            setSubmitting(false);
            dispatch(setUploadingProgress(0));
            console.log(error, 'spotlight add error');
            dispatch(slice.actions.hasError(error));
        }
    };
};

export const editSpotlight = (values, setSubmitting, enqueueSnackbar, reset, state, setState) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { mainImage, subImage, userId, ...rest } = values;
            let payload = {
                ...rest,
                userId: userId,
                vanity: convertToDashed(values.vanity)
            }

            if (mainImage && mainImage.length) {
                const { uploadedFileUrl: mainImageUrl } = await uploadFile(mainImage[0], 'spotlight');
                payload.mainImage = mainImageUrl;
            }

            if (subImage && subImage.length) {
                const { uploadedFileUrl: subImageUrl } = await uploadFile(subImage[0], 'spotlight');
                payload.subImage = subImageUrl;
            }

            const response = await axios.post(`${ajaxUrl}/spotlight/update`, payload);

            dispatch(slice.actions.editSpotlightSuccess(response.data?.data));
            setState();
            reset();
            setSubmitting(false);
            enqueueSnackbar(`Update success, Spotlight Updated Successfully.`, { variant: 'success' });
            dispatch(setUploadingProgress(0));
        } catch (error) {
            setSubmitting(false);
            dispatch(setUploadingProgress(0));
            console.log(error, 'spotlight add error');
            dispatch(slice.actions.hasError(error));
        }
    };
};
export const deleteSpotlight = (v, handleClose, setDltLoading) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const resp = await axios.post(`${ajaxUrl}/spotlight/delete/${v._id}`);
            dispatch(slice.actions.deleteSpotlightSuccess(v));
            // setSpotlights(prev => prev?.filter((val) => val._id !== v._id))
            console.log(resp);
            handleClose();
            setDltLoading(false);
        } catch (error) {
            setDltLoading(false);
            dispatch(slice.actions.hasError(error));
        }
    };
};
