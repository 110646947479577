import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';

import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 60;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP 
  },
  // boxShadow: '0px 10px 60px rgba(0, 0, 0, 0.8)',
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,

}));

const LogoImgStyle = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: '200px',

  filter: `drop-shadow(40px 80px 80px rgba(0, 0, 0, 0.28))`,
  [theme.breakpoints.up('lg')]: {
    width: '100%',

  }
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar color={isHome ? 'transparent' : 'transparent'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset ? {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 10 },
            boxShadow: 'none',
          } : { background: `${isHome ? 'linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(24, 24, 24, 0) 100%)' : '#181818'}` 
        })
        }}
        style={isOffset ? {
          paddingBottom: '0',
        } : {}}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/" aria-label="landing">
            {/* <Logo /> */}
            <LogoImgStyle alt="over" src={isOffset ? "/static/brand/si dark.png" : "/static/brand/si.png"} />
          </RouterLink>
          {/* <Label color="dark" sx={{ ml: 1 }}>
            Screen Indie
          </Label> */}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {/* <Button variant="contained" target="_blank" href="https://screen-indie.com/">
            Sign Up
          </Button> */}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
