import axios from 'axios';
import { ajaxUrl } from 'src/config';

const CHUNK_SIZE = 1024 * 1024 * 4608; // 4.5GB

export async function uploadFile(file, path, callbackProgress = () => {}) {
  const totalParts = Math.ceil(file.size / CHUNK_SIZE);
  const { signedUrls, uploadId, uploadKey } = await getSignedUrlApi(path, file.name, file.type, totalParts);
  let uploadedParts = [];

  callbackProgress(0);

  for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
    const start = (partNumber - 1) * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const fileChunk = file.slice(start, end);
    const axiosInstance = axios.create();

    const uploadedPart = await axiosInstance.put(signedUrls[partNumber - 1].signedUrl, fileChunk, {
      headers: null,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((((partNumber - 1) * CHUNK_SIZE) + progressEvent.loaded) / file.size * 100);
        callbackProgress(percentCompleted - 5);
      }
    });

    uploadedParts.push({ ETag: uploadedPart.headers['etag'] || '', PartNumber: partNumber });
  }

  const uploadedFileUrl = await uploadCompleteApi(uploadId, uploadKey, uploadedParts);

  callbackProgress(100);

  return { uploadedFileUrl, uploadKey };
}

export async function uploadVideo(file, path, callbackProgress = () => {}) {
  const result = await uploadFile(file, path, callbackProgress);

  if (file.type === 'video/mp4') {
    return result;
  }

  return transcodeApi(result.uploadKey);
}

async function getSignedUrlApi(path, fileName, mimetype, totalParts) {
  const result = await axios.post(`${ajaxUrl}/aws/upload/signed-urls`, {
    path,
    fileName,
    mimetype,
    totalParts
  });

  return result.data;
}

async function uploadCompleteApi(uploadId, uploadKey, parts) {
  const result = await axios.post(`${ajaxUrl}/aws/upload/complete`, {
    uploadId,
    uploadKey,
    parts
  });

  return result.data;
}

async function transcodeApi(uploadKey) {
  const result = await axios.post(`${ajaxUrl}/aws/transcode`, {
    uploadKey
  });

  return result.data;
}